import React, { useEffect, useState, useRef } from "react";
import { baseBlade } from "../images/blade";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../styles/Header.css";

const Header = (props) => {   

    /***
     *     __  ___      ___  ___  __  
     *    /__`  |   /\   |  |__  /__` 
     *    .__/  |  /~~\  |  |___ .__/ 
     *                                
     */




    /***
     *          __   ___     ___  ___  ___  ___  __  ___  __  
     *    |  | /__` |__     |__  |__  |__  |__  /  `  |  /__` 
     *    \__/ .__/ |___    |___ |    |    |___ \__,  |  .__/ 
     *                                                        
     */

  useEffect(() => {
    

  }, []);



    /***
     *     ___            __  ___    __        __  
     *    |__  |  | |\ | /  `  |  | /  \ |\ | /__` 
     *    |    \__/ | \| \__,  |  | \__/ | \| .__/ 
     *                                             
     */



    /***
     *     __   __            __   ___ ___       __       
     *    |  \ /  \  |\/|    |__) |__   |  |  | |__) |\ | 
     *    |__/ \__/  |  |    |  \ |___  |  \__/ |  \ | \| 
     *                                                    
     */

  return (
    <div className="AppHeader">
        <div className="AppLogoWrap">
            <div className="AppLogo"><img src={baseBlade} alt="" width="45" heigt="45"></img></div>
            <div className="AppLogoText">{props.gameID !== '' ? "Scrum Poker | Game ID: " + props.gameID : "Scrum Poker"}</div>
        </div>
        <div className="AppUserInfo">
            <div className="AppUsername">{props.displayName}</div>
            {props.gameStatus === "running" ? <button onClick={() => props.handleInviteModal()} className="inviteBtn">Spieler einladen&nbsp; <FontAwesomeIcon icon="fa-solid fa-people-pulling" /></button> : null}
            
        </div>
    </div>
  );
};


export default Header;